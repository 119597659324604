import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import styled from 'styled-components';
import { connect } from 'react-redux';

import LogoImg from '../assets/logo1-128.png';
import { trackEvent } from '../services/analytics';
import featureSwitch from '../services/featureSwitch';
import routesMap from '../Routes';

import OrderContainer from '../pagesComponents/common/OrderContainer';
import { mobileThresholdPixels, colors, Wrapper, Button, Block, Header2, Title2, Logo }
  from '../components/styledComponents';
import android from '../assets/store.android.svg';
import iOS from '../assets/store.ios.svg';
import message from '../assets/message.svg';

const TextLink = styled.span`
  color: ${colors.navy};
  text-decoration: underline;
  cursor: pointer;
`;

const P = styled.p`
  color: ${colors.navy};
  text-align: center;
  padding: 20px;
  font-weight: 300;
  margin: 20px 0px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 10px 0px;
    text-align: left;
    font-size: 12px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Img = styled.img``;
const Icon = styled.img`
  margin: 10px;
`;

const Row = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

function reinitOrder(trackingOriginElement, dispatch) {
  trackEvent('click', trackingOriginElement);
  dispatch({ type: 'ORDER.INIT' });
  dispatch({ type: 'CUSTOMER.INIT' });
}

const renderDefaultText = order => (
  <TextContainer>
    <P>
      On s’occupe de tout ! Pas la peine d’encaisser les retouches des clients ;)
    </P>

    <Img src={message} />

    <P>
      Un e-mail pour confirmer la bonne réception de la demande du client lui est directement envoyé après validation.
    </P>

    {order && !order.cardToken && !featureSwitch('order_thirdPersonLabels') &&
      <Row>
        <Link
          to={`${routesMap.Payment.url}/${order._id}/${order.customer._id}`} // eslint-disable-line
          style={{ flex: 1, textAlign: 'center' }}
        >
          <Button noMargin>{'enregistrer ma carte'}</Button>
        </Link>
      </Row>
    }

    <P>
      {featureSwitch('order_thirdPersonLabels') ?
        'Pour suivre sa commande ou parrainer ses amis, votre client peut se connecter sur notre application :' :
        'Pour suivre votre commande ou parrainer vos amis, vous pouvez vous connecter sur notre application :'
      }
    </P>
  </TextContainer>
);

const renderTitle = () => (
  featureSwitch('order_thirdPersonLabels') ?
    'la commande de votre client a bien été enregistrée' :
    'votre commande a bien été enregistrée'
);

class Success extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch({ type: 'ORDER.INIT' });
  }

  render() {
    const { dispatch, order } = this.props;
    return (
      <OrderContainer>
        <Wrapper>
          <Block>
            <Header2>
              <Link
                to={'/'} style={{ flex: 1, textAlign: 'center' }}
                onClick={() => reinitOrder('home_success', dispatch)}
              >
                <Logo src={LogoImg} />
              </Link>
              <Title2>{renderTitle()}</Title2>
            </Header2>

            {renderDefaultText(order)}

            <Row>
              <a
                target="_blank" rel="noreferrer noopener"
                href="https://play.google.com/store/apps/details?id=fr.tilli.app"
                onClick={() => trackEvent('click', 'store_android_success')}
              >
                <Icon src={android} alt="application Android" />
              </a>
              <a
                target="_blank" rel="noreferrer noopener"
                href="https://itunes.apple.com/us/app/tilli/id1280578121"
                onClick={() => trackEvent('click', 'store_iOS_success')}
              >
                <Icon src={iOS} alt="application iPhone" />
              </a>
            </Row>


            <Link
              to={'/'}
              style={{ flex: 1, textAlign: 'center' }}
              onClick={() => reinitOrder('home_success', dispatch)}
            >
              <TextLink>retour à l‘accueil</TextLink>
            </Link>
          </Block>
        </Wrapper>
      </OrderContainer>
    );
  }
}

Success.propTypes = {
  dispatch: PropTypes.func.isRequired,
  order: PropTypes.shape({}),
};

Success.defaultProps = {
  order: null,
};

const mapStateToProps = state => ({
  clothes: state.order.clothes,
  order: state.order.submittedOrder,
});

const mapDispatchToProps = dispatch => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(Success);
